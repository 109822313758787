var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.componentName,{tag:"component",staticClass:"video-slide",style:(_vm.styles),attrs:{"to":_vm.to}},[(_vm.$userAgent.isDesktop)?_c('ClientOnly',{attrs:{"placeholder":"Loading..."}},[_c('YoutubeVideo',{staticClass:"video-slide-video",attrs:{"video-id":_vm.get(_vm.slide, 'fields.extraFields.video_id'),"options":{
                enablejsapi: 1,
                disablekb: 1,
                controls: 0,
                rel: 0,
                iv_load_policy: 3,
                cc_load_policy: 0,
                playsinline: 1,
                showinfo: 0,
                modestbranding: 1,
                fs: 0,
                mute: 1,
                autoplay: 1,
                loop: 1
            }}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"video-slide-body"},[(_vm.get(_vm.slide, 'fields.title'))?_c('h2',{staticClass:"text-2xl font-light"},[_vm._v(_vm._s(_vm.get(_vm.slide, 'fields.title')))]):_vm._e(),_vm._v(" "),(_vm.get(_vm.slide, 'fields.subtitle'))?_c('h3',{staticClass:"text-xl font-light"},[_vm._v(_vm._s(_vm.get(_vm.slide, 'fields.subtitle')))]):_vm._e(),_vm._v(" "),(_vm.get(_vm.slide, 'fields.description'))?_c('Markdown',{attrs:{"source":_vm.get(_vm.slide, 'fields.description')}}):_vm._e(),_vm._v(" "),(_vm.get(_vm.slide, 'fields.link'))?_c('div',{staticClass:"inline-flex items-center gap-1 font-light cursor-pointer"},[_vm._v("\n            "+_vm._s(_vm.get(_vm.slide, 'fields.link.fields.title'))+"\n            "),_c('IconNext',{staticClass:"h-6"})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }